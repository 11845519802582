<template>
    <v-container class="houses" :style="{'max-width': '1300px'}">
      <keep-alive>
        <ModalAddCommentHouseSet ref="addCommentHouseSet" :is_global="true" v-on:update-job="getHouseComments"/>
      </keep-alive> 
      <keep-alive>
        <ModalChangeCommentHouseSet ref="changeCommentHouseSet" v-on:update-job="getHouseComments"/>
      </keep-alive> 
    
      <v-row class="mt-3 mb-2" justify="center">
        <v-col
          cols="12"
          sm="4"
          md="3"
        >
          <SearchStreet  v-on:search-new-id="setSearchValue" :denseForm="true" :outlinedForm="true" :clearSearchStreet="clearSearchStreet"/>
        </v-col>
        <v-col
          cols="6"
          sm="4"
          md="2"
        >
        <v-text-field 
          dense
          outlined
          hide-details
          label="Будинок"
          v-model="form_data.house"
          type="number"
          clearable
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          sm="4"
          md="2"
        >
        <v-select 
          dense
          outlined
          hide-details
          label="Літера"
          v-model="form_data.letter"
          :items="['Без літери','а','б','в','г','д','е','ж','з','и','к','л','м','н','о','п']"
          clearable
          >
          </v-select> 
        </v-col>
        <v-col
          cols="6"
          sm="4"
          md="2"
        >
        <v-select 
          dense
          outlined
          hide-details
          label="Тип коментаря"
          item-text="name"
          item-value="id"
          v-model="form_data.comment_subj"
          :items="comment_type_house"
          clearable
          >
          </v-select> 
        </v-col>
        <v-col cols="6" sm="2" md="1">
          <v-row title="Тільки свій оператор" class="mx-0 px-0 d-flex" justify="center">
            <v-checkbox
              
              v-model="form_data.only_my"
            ></v-checkbox>
            <v-icon :color="form_data.only_my ? 'primary': ''">mdi-account</v-icon>
            
          </v-row>
        </v-col>
        <v-col
          cols="6"
          sm=2
          md="2"
          class="text-center text-md-end"
        >
          <v-btn color="primary lighten-1" @click="checkGetHouseComments()"
          >
          <v-icon left>mdi-magnify</v-icon>
            Пошук
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
          :item-class="checkDelete"
					:headers="headers"
					loading-text="Завантаження..."
					no-data-text="Немає даних для відображення"
					:server-items-length="paginations.count"
					:items="comments"
					:options.sync="options"
          :page.sync="page"
					:loading="loading"
					:footer-props="footer"
					class="elevation-1 row-cursor"
					focusable
					disable-sort
				>
					<template v-slot:top>

						<v-toolbar flat color="secondary lighten-1" dark >
							<v-toolbar-title>
								<h3 v-if="$vuetify.breakpoint.mdAndUp" class="mr-6 accent--text">Коментарі до Будинків</h3>
								<h3 v-else class="mr-6 accent--text">Коментарі</h3>
              </v-toolbar-title>

							<v-spacer />
                <NavigationButtons :options="options" :paginations="paginations" :showDetailsOfSmallPage="true" :disabledButtons="loading" v-on:prev-page="page -= 1" v-on:next-page="page += 1"/>
              <v-spacer />
              
              <v-btn 
                icon  
                @click="$refs.addCommentHouseSet.show({street_name: street_name, street: form_data.street, house: form_data.house, letter: form_data.letter})"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn 
                :loading="loading"
                icon  
                @click="getHouseComments"
              >
                <v-icon>autorenew</v-icon>
              </v-btn>
						</v-toolbar>
					</template>

          <!-- ---------------------------------------- Cols ----------------------------------------- -->

          <template #item.index="{ item }">
                <span>{{ comments.indexOf(item) + paginations.start_index }}</span>
          </template>

          <template #item.house="{ item }">
            {{ item && `${item.house || ''}${item.letter || ''}`}}{{ item && item.corpus > 0 && ` (к.${item.corpus})` || '' }}
          </template>
          


          <template #item.comment_subj="{ item }">
            <div class="" style="white-space: nowrap;">
              <v-avatar class="mr-2" size="28" :color="item.delete ? 'grey' : icons[item.comment_subj].color">
                <v-icon
                small
                  dark
                >
                {{ icons[item.comment_subj].icon }}
                </v-icon>
              </v-avatar>
              {{ item && item.comment_subj }}
            </div>
            
          </template>
          <template #item.comment="{ item }">
            <span>{{ item.comment }}</span>
            <v-chip v-if="item.delete" class="mb-1 ml-1" x-small outlined color="error">Видалений</v-chip>

          </template>

          

          <template #item.edit="{ item }">
            <div  class="d-flex justify-end">
              <v-btn v-if="item.pictures" title="Зображення" icon color="primary" @click="dialogPicturesSrc = item.pictures, dialogPictures = true">
                <v-icon>mdi-image-outline</v-icon>
              </v-btn>
              <v-edit-dialog v-if="false"

                :return-value.sync="item.comment"
                large
                cancel-text="Закрити"
                save-text="Змінити"
                @save="changeComment(item.id, item.comment)"
              >
              <v-btn v-if="!item.delete" title="Редагувати" icon >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
                
                <template v-slot:input>
                  <v-textarea 
                  style="width: 300px; padding-top: 20px;"
                    v-model="item.comment"
                    label="Коментар"
                    rows="5"
                  ></v-textarea>
                </template>
              </v-edit-dialog>

              <v-btn v-if="!item.delete" title="Редагувати" icon @click="$refs.changeCommentHouseSet.show(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              
              
              <template>
                <v-btn v-if="!item.delete" title="Видалити" icon @click="openConfirmDialog(item)">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
                <v-btn v-else title="Повернути" icon @click="openConfirmDialogRefresh(item)">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>

              <v-btn v-if="!item.delete" title="Поділитись" icon 
              target="_blank" 
              :href="`https://t.me/share/url?url=${encodeURIComponent(`Пересланий коментар будинку: `)}&text=${encodeURIComponent(`**\n${item.street_name }, ${item.house }${item.letter }**\n( ${item.comment_subj} ): ${item.comment}`)}`"   
              >
                <v-icon small>mdi-share-variant</v-icon>
              </v-btn>
            </div>
          </template>

          <!-- ---------------------------------------- /Cols ----------------------------------------- -->
          
          <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
            {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
          </template>
			</v-data-table> 

      <v-dialog v-model="dialog_refresh" persistent max-width="330">      
        <v-card>
          <v-card-title class="text-h5 text-center">
            Повернути коментар?
          </v-card-title>
          <v-card-text class="text-center">Ви впевнені, що хочете повернути коментар?</v-card-text>
          <v-card-actions>
            <v-btn
              color="deep-orange darken-1"
              text
              @click="closeConfirmDialogRefresh"
            >
              Ні
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary lighten-1"
              text
              @click="refreshComment"
            >
              Так
            </v-btn>
          </v-card-actions>
        </v-card>   
      </v-dialog>  

      <v-dialog v-model="dialog" persistent max-width="330">      
        <v-card>
          <v-card-title class="text-h5 text-center">
            Видалити коментар?
          </v-card-title>
          <v-card-text class="text-center">Ви впевнені, що хочете видалити коментар?</v-card-text>
          <v-card-actions>
            <v-btn
              color="deep-orange darken-1"
              text
              @click="closeConfirmDialog"
            >
              Ні
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary lighten-1"
              text
              @click="deleteComment"
            >
              Так
            </v-btn>
          </v-card-actions>
        </v-card>   
      </v-dialog>  
      <v-dialog
        v-model="dialogPictures"
        width="500"
      >
        <div class="d-flex justify-center" v-if="dialogPicturesSrc">
          <v-img
            lazy-src=""
            :src="dialogPicturesSrc"
            @click="dialogPictures = false"
          >
        </v-img>
        </div>
      </v-dialog>  
    </v-container>
</template>

<script>
import axios from "axios"
import {mapGetters} from 'vuex'
import ModalAddCommentHouseSet from '@/components/Job/ModalAddCommentHouseSet.vue'
import ModalChangeCommentHouseSet from '@/components/Job/ModalChangeCommentHouseSet.vue'
import SearchStreet from '@/components/Tools/SearchStreet.vue'

export default {
    name: 'Houses',
    components: {
      ModalAddCommentHouseSet,
      ModalChangeCommentHouseSet,
      SearchStreet,
      NavigationButtons: () => import('@/components/Abonents/NavigationButtons.vue')
    },

    data: () => ({
      dialog: false,
      dialog_refresh: false,
      dialogPictures: false,
      dialogPicturesSrc: '',
      deleteItem: {},
      refreshItem: {},
      clearSearchStreet: 0,
      street_name: '',
      loading: false,
      form_data: {},
      house_comments: [],
      page: 1,
      notComments: false,
      options: {},
      comments: [],
      paginations: {},
      headers: [
        { text: '#', value: 'index', sortable: false,  width: 50,},
        //{ text: '#', value: 'id', sortable: false,  width: 50},
        { text: 'Дата', value: 'date', },
        { text: 'Вулиця', value: 'street_name', },
        { text: 'Буд', value: 'house',  sortable: false,},
        { text: 'Тип', value: 'comment_subj', sortable: false,},
        { text: 'Коментар', value: 'comment', sortable: false, },
        { text: 'Створив', value: 'user', sortable: false, },
        { text: '', value: 'edit', sortable: false, },
      ],
      footer: {
          "items-per-page-options": [10,25,50],
          "items-per-page-text": " ", //"Абонентів на сторінці:"
          "items-per-page-all-text": "Всі",
          "show-first-last-page": true,
          "show-current-page": true,
      },
      icons: {
        "Доступ": { icon: "mdi-key-outline", color: "deep-orange"},
        "Управителі": {icon: "mdi-account-box-outline", color: "blue"},
        "Технічні данні": {icon: "mdi-tools", color: "primary"},
        "Інше": {icon: "mdi-text", color: "grey"},
        "PON": {icon: "mdi-connection", color: "light-green"},
      }
    }),
    computed: {
      ...mapGetters({
        //streets: "streets/streets"
        comment_type_house: "job_types/comment_type_house"
        
      }),
    },
    watch:{
      options: {
				handler (val, oldVal) {
					oldVal.page && this.getHouseComments();
				},
				deep: true,
			},
    },
    methods:{
      checkDelete(item){
        return item.delete && "read--notification" || "no-read--notification"
      },
      setSearchValue(val, name){
          //console.log("This is a setSearchValue", val, name)
          this.form_data.street = val
          this.street_name = name
          //this.filters.find(x => x.name == "street").value = val
          //this.filters.find(x => x.name == "street").street_name = name
      },
      checkGetHouseComments(){
        if(this.options.page == 1){
          this.getHouseComments();
        }else{
          this.page = 1;
        }
      },
      filterFormat() {
				const temp = {};
				temp.ordering = !this.options.sortDesc[0] && '-' + this.options.sortBy[0] || this.options.sortBy[0];
				temp.itemsPerPage = this.options.itemsPerPage;
				temp.page = this.options.page;


				return temp; 
			},
      getHouseComments(){
        this.loading = true
        /** Кастомізація фільтру по літері */
        let send_data = JSON.parse(JSON.stringify(this.form_data)) 
        if(send_data.letter && send_data.letter == 'Без літери') send_data.letter = 'blank'
        /* ------------------------------ */
          axios.get('/api/service/house_comments/', {params: {...send_data, ...this.filterFormat()}})
          .then( response =>{
              //console.log(response.data) 
              this.paginations = response.data.paginations
              this.comments = response.data.results
          })
          .catch(() => {
              //
          })
          .finally(() => (this.loading = false));
      },
      changeComment(id_comment, text_comment){
        axios({
            headers: { "Content-type": "application/json; charset=UTF-8" },
            method: 'PUT',
            url: `/api/service/house_comments/${id_comment}/`,
            data: {comment: text_comment},
        })
        .then(() => {
            this.$router.app.$snack.show("Коментар змінено!");
            this.getHouseComments()
        })
        .catch(err => {
            this.$router.app.$sheet.show("Помилка!", err)
        })
      },
      deleteComment(){ 
        axios({
            headers: { "Content-type": "application/json; charset=UTF-8" },
            method: 'DELETE',
            url: `/api/service/house_comments/${this.deleteItem.id}/`,
        })
        .then(() => {
            this.closeConfirmDialog()
            this.$router.app.$snack.show("Коментар видалено!");
            this.getHouseComments()
            
            
        })
        .catch(err => {
            this.$router.app.$sheet.show("Помилка!", err)
        })
      },
      refreshComment(){
        axios({
            headers: { "Content-type": "application/json; charset=UTF-8" },
            method: 'PUT',
            url: `/api/service/house_comments/${this.refreshItem.id}/`,
        })
        .then(() => {
            this.closeConfirmDialogRefresh()
            this.$router.app.$snack.show("Коментар Повернено!");
            this.getHouseComments()
        })
        .catch(err => {
            this.$router.app.$sheet.show("Помилка!", err)
        })
      },
      openConfirmDialog(item){
        this.deleteItem = item 
        this.dialog = true
      },
      openConfirmDialogRefresh(item){
        this.refreshItem = item 
        this.dialog_refresh = true
      },
      closeConfirmDialog(){
        this.deleteItem = {}
        this.dialog = false
      },
      closeConfirmDialogRefresh(){
        this.refreshItem = {}
        this.dialog_refresh = false
      }
    }

}
</script>
