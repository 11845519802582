<template>
        
        <v-dialog v-model="dialog" persistent max-width="500">
            
            <v-card>
            <v-card-title class="">
            <span class="headline">{{ abonentData.title }} </span>
            </v-card-title>
            
            <v-card-text>
                
            <v-container class="">
                <v-form 
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-col cols="12" md=12> 
                        <div class="mb-4 text-center">
                                <h4>{{ abonentData.header }}</h4>
                            </div>
                    <v-row class="pt-2">
                        <v-col v-if="!typeChangeModal && form_data.service == 2" :cols="12"  class="py-2" >
                            <v-text-field 
                                v-model="loginToChange"
                                dense
                                outlined
                                hide-details
                                label="Логін (змінюється в картці)"
                                :rules="textRules || notRules"
                                name="login"
                                type="text"
                                >
                                </v-text-field>
                        </v-col>

                            
                            <v-col v-for="field in fieldsService" :key="field.name" :title="field.detail" :cols="$vuetify.breakpoint.smAndUp && field.cols || 12"  class="py-2">
                                <v-select v-if="field.type == 'select'"
                                dense
                                outlined
                                hide-details="auto"
                                :label="field.title"
                                v-model="form_data[field.name]"
                                :name="field.name"
                                :rules="field.rules && textRules || notRules"
                                :items="field.values"
                                item-text="name"
                                item-value="id"
                                :disabled="field.disabled"
                                @change="field.name == 'operator' && abonentData.type == 'add_tv' && createValuesRates()"
                                >
                                <template v-slot:prepend-item v-if="field.name == 'tarif'">
                                    <v-subheader style="height: 30px;">Фільтри:</v-subheader>
                                    <FiltersTarifs ref="filtersTarifs" v-on:create-values-rates="createValuesRates()" v-on:aplly-filters-tarifs="apllyFiltersTarifs()" :is_special_tarif_for_distributor="is_special_tarif_for_distributor" service="tv"/>
                                    <v-divider />
                                </template>
                                </v-select>
                                <v-checkbox v-else-if="field.type == 'checkbox'"
                                    v-model="form_data[field.name]"
                                    :disabled="field.disabled || field.disabledOrg && (abonent && abonent.is_organization) || field.disabledNotAltair && (user && user.operator_id != 1)"
                                    
                                    :label="field.title"
                                    hide-details="auto"
                                    class="mt-0 mb-0"
                                ></v-checkbox>

                                <v-switch v-else-if="field.type == 'switch'"
                                dense
                                hide-details
                                :label="field.title"
                                v-model="form_data[field.name]"
                                :disabled="field.disabled"
                                ></v-switch>
                                
                                <v-divider v-else-if="field.type == 'divider'" class="mt-5" />

                                <v-text-field v-else
                                dense
                                outlined
                                hide-details="auto"
                                :label="field.title"
                                v-model="form_data[field.name]"
                                :name="field.name"
                                :rules="field.rules && textRules || notRules"
                                :type="field.type"
                                :disabled="field.disabled"
                                >
                                </v-text-field>
                            </v-col>
                            <small v-if="!abonentData.summ && abonent && abonent.is_organization" class="px-3">
                                * Дані для юридичних осіб не передаються у Нова-Ком та Приват24
                                
                                <template v-if="abonentData.not_change_tarif">
                                    <br>
                                    * Юридична особа не може змінювати собі тариф у особистому кабінеті
                                </template>
                            </small>
                            <v-col v-if="abonentData.summ" cols="12" class="py-2">
                                <keep-alive>
                                    <AbonentTarifSumm ref="AbonentTarifSumm" :abonent_id="abonent && abonent.id" :form_data="form_data"/>
                                </keep-alive>
                            </v-col>
                    </v-row>
                    </v-col>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <v-btn v-if="!typeChangeModal" :loading="loadingSubmit" :disabled="loadingSubmit"
                color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="abonentData.service == 1 && sendCreateServiceTv() || sendCreateServiceInet()">
                    Додати
                </v-btn>
                <v-btn v-else :loading="loadingSubmit" :disabled="loadingSubmit"
                color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="abonentData.service == 1 && sendChangeServiceTv() || sendChangeServiceInet()">
                    Змінити
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from "axios"
    import AbonentTarifSumm from '@/components/AbonentInfo/AbonentTarifSumm.vue'
    import { mapGetters } from "vuex"
	export default {
        components:{
            AbonentTarifSumm
        },
        name: 'create-service-modal',
        props: ["user", "abonent", "service_tv", "service_inet", "service_inet_billing",  "checkDoubleTarif"],
		data: () => ({
            is_special_tarif_for_distributor: false,
            loginToChange: '',
            panel: false,
            loadingSubmit:false,
            typeChangeModal: false,
            item: {},
            abonentData: {},
            countChangeAbonentData: 0,
            defaultAbonentData: {
                add_tv: {service: 1, title: "Додати Послугу",   method: "POST", readonly: false, message: "Послугу додано!", url: "/api/cabletv/create_user_service/", header: "ТЕЛЕБАЧЕННЯ", summ: true, type: 'add_tv'},
                add_inet: {service: 2, title: "Додати Послугу", method: "POST", readonly: true,  message: "Послугу додано!", url: "/api/cabletv/create_user_service/", header: "ІНТЕРНЕТ"},
                change_tv: {service: 1, title: "Змінити Послугу", method: "PUT", readonly: true,  message: "Данні послуги змінено!", url: "", header: "ТЕЛЕБАЧЕННЯ", server_message: true},
                change_inet: {service: 2, title: "Змінити Послугу", method: "POST", readonly: true,  message: "Данні послуги змінено!", url: "", header: "ІНТЕРНЕТ", not_change_tarif: true}
            },
            fieldsService: [],
            dialog: false,
            valid: true,
            form_data: {},
            
            fieldsTv: [
                //{title: "Послуга", name: "service",  rules: false,  type: "select",     disabled: true, value: "", values: [{id: 1, name: 'Телебачення'},{id: 2, name: "Інтернет"}] },
                {title: "№ Договору", name: "contract",      rules: false,  type: "number",     disabled: false, value: "" },
                {title: "Дата підключення", name: "date_tarif_change",      rules: true,  type: "date",     disabled: false, value: "" },
                {title: "Тариф",         name: "tarif",     rules: true,  type: "select",   disabled: false, value: "", values: []},
                {title: "Постачальник",   name: "operator",   rules: true,  type: "select",   disabled: false, value: "" },
                {title: "Нова-Ком",     detail:"", name: "novakom",   rules: false,  type: "select",   disabled: false, value: "", values: [{id: 0, name: "Не передавати дані"}, {id: 1, name: "Передавати нульові значення"}, {id: 2, name: "Передавати баланс абонента"}, {id: 3, name: "Передавати баланс без переплати"}] },
                //{title: "Сумма",         name: "balance",   rules: false,  type: "number",   disabled: true, value: "", },
                //{title: "Статус",        name: "status",    rules: false,  type: "switch",   disabled: false,  value: "" },
                //{title: "Квартиранти",   name: "owner",     rules: false,  type: "switch",   disabled: false, value: "" },
                //{title: "НоваКом",       name: "novakom",   rules: false,  type: "switch",   disabled: false, value: "" },
                //{title: "Борг",       name: "balance_novakom",   rules: false,  type: "switch",   disabled: false, value: "" },
            ],
            fieldsTvChange: [
                //{title: "Послуга", name: "service",  rules: false,  type: "select",     disabled: true, value: "", values: [{id: 1, name: 'Телебачення'},{id: 2, name: "Інтернет"}] },
                {title: "№ Договору", name: "contract",      rules: true,  type: "number",     disabled: false, value: "" },
                {title: "Дата договору", name: "date_contract",  rules: true,  type: "date",  disabled: false, value: "" },
                //{title: "Тариф",         name: "tarif",     rules: true,  type: "select",   disabled: false, value: "", values: []},
                {title: "Постачальник",   name: "operator",   rules: false,  type: "select",   disabled: false, value: "" },
                {title: "Нова-Ком",     detail:"", name: "novakom",   rules: false,  type: "select",   disabled: false, value: "", values: [{id: 0, name: "Не передавати дані"}, {id: 1, name: "Передавати нульові значення"}, {id: 2, name: "Передавати баланс абонента"}, {id: 3, name: "Передавати баланс без переплати"}] },
                {title: "Заборонити платежі", detail:"Заборонити отримання платежів через платіжні системи", name: "deny_pay",   rules: false,  type: "checkbox", disabledOrg: true,   disabled: false, value: "", },
                //{title: "Нова-Ком", detail:"Передавати дані абонента в Нова-Ком",       name: "novakom",   rules: false,  type: "checkbox", disabledOrg: true,   disabled: false, value: "", cols: 5 },
                //{title: "Нова-Ком Баланс", detail:"Передавати баланс абонента в Нова-Ком",       name: "balance_novakom",   rules: false,  type: "checkbox",   disabled: false, disabledOrg: true, value: "", cols: 7 },
            ],
            fieldsInet: [
                //{title: "Дата Договору",     name: "date_contract",     rules: true,  type: "date",     value: ""},
                //{title: "№ Договору",        name: "contract", rules: true,  type: "text",     value: ""},
                //{title: "Тариф",             name: "tarif",    rules: true,  type: "select",   value: "", disabled: false, values: []},
                {title: "Постачальник",      name: "operator",   rules: true,  type: "select",   disabled: false, value: "" },
                {title: "Нова-Ком",     detail:"", name: "novakom",   rules: false,  type: "select",   disabled: false, value: "", values: [{id: 0, name: "Не передавати дані"}, {id: 1, name: "Передавати нульові значення"}, {id: 2, name: "Передавати баланс абонента"}, {id: 3, name: "Передавати баланс без переплати"}] },
                //{title: "НоваКом",  detail:"Передавати дані абонента в Нова-Ком",     name: "novakom",   rules: false,  type: "checkbox", disabledOrg: true,   disabled: false, value: "", cols: 5 },
                //{title: "Нова-Ком Баланс",  detail:"Передавати баланс абонента в Нова-Ком",      name: "balance_novakom",   rules: false,  type: "checkbox", disabledOrg: true,   disabled: false, value: "", cols: 7 },

            ],
            fieldsInetChange: [
                {title: "№ Договору",     name: "contract", rules: true,  type: "text",     value: ""},    
                {title: "Дата Договору",  name: "date_contract",     rules: true,  type: "date",     value: ""},
                //{title: "Тариф",             name: "tarif",    rules: false,  type: "select",   value: "", disabled: false, values: []},
                {title: "Нова-Ком",     detail:"", name: "novakom",   rules: false,  type: "select",   disabled: false, value: "", values: [{id: 0, name: "Не передавати дані"}, {id: 1, name: "Передавати нульові значення"}, {id: 2, name: "Передавати баланс абонента"}, {id: 3, name: "Передавати баланс без переплати"}] },
                {title: "Заборонити платежі", detail:"Заборонити отримання платежів через платіжні системи", name: "deny_pay",   rules: false,  type: "checkbox", disabledOrg: true,   disabled: false, value: "", },
                
                //{title: "Нова-Ком", detail:"Передавати дані абонента в Нова-Ком",       name: "novakom",   rules: false,  type: "checkbox", disabledOrg: true,   disabled: false, value: "", cols: 5 },
                //{title: "Нова-Ком Баланс", detail:"Передавати баланс абонента в Нова-Ком", name: "balance_novakom",   rules: false,  type: "checkbox", disabledOrg: true,    disabled: false, value: "", cols: 7 },
                {title: "", name: "",   rules: false,  type: "divider",    disabled: false, value: ""},
                {title: "Зміна тарифу", detail:"Абонент може змінювати собі тариф у особистому кабінеті",       name: "check_change_tarif",   rules: false,  type: "checkbox", disabledOrg: true,    disabled: false, value: "", cols: 6},
                {title: "Біла IP адреса", detail: "Дозволити 'білу' IP адресу для абонента", name: "check_white_ip",   rules: false,  type: "checkbox", disabledOrg: true, disabledNotAltair: true,    disabled: false, value: "", cols: 6},
                //{title: "Перенос залишків", detail:"Переносити залишок коштів на на наступний місяць", name: "check_transfer_money",   rules: false,  type: "checkbox",   disabled: false, value: "", cols: 7 },
                //{title: "При зміні глобальних тарифів", detail:"При вступі в силу нових тарифів у всьому ТРК", name: "check_change_tarif_global",   rules: false,  type: "select",   disabled: false, value: "", values: [{id: 0, name: "Обнуляти знижку"}, {id: 1, name: "Не змінювати знижку, змінити суму абонплати"}, {id: 2, name: "Не змінювати суму абонплати, перераховувати знижку"}] },
                //{title: "Постачальник",   name: "operator",   rules: false,  type: "select",   disabled: false, value: "" },
            ],
            fieldsInetChangeSecond: [
               //
            ],
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        computed: {
            ...mapGetters({
                rates_tv: "filters/rates_tv",
                rates_tv_special: "filters/rates_tv_special",
                operators: "filters/operators",
                operators_groups: "filters/operators_groups" 
            }),
        },
        
        mounted(){
            //this.fields.find(x => x.name == 'street').values = this.streets 
            //this.fieldsTv.find(x => x.name == "tarif").values = this.rates
            //this.fieldsInet.find(x => x.name == "tarif").values = this.rates
            //this.fieldsTvChange.find(x => x.name == "tarif").values = this.rates
            //this.fieldsInetChange.find(x => x.name == "tarif").values = this.rates
            this.abonentData = []
            //console.log("mounted AddServiceModal")

        },
        watch:{
            form_data:{
                handler () {
                    //console.log("form_data GHANGE!")
                    this.countChangeAbonentData += 1
                    //console.log(this.form_data)
                    this.countChangeAbonentData > 0 && this.abonentData.summ && this.$refs.AbonentTarifSumm.getSumm()
                },
                deep: true,
            },
            
        },
        methods: {
            show(type, service){
                //console.log(order);
                this.abonentData = JSON.parse(JSON.stringify(this.defaultAbonentData[type]));

                if(service == 'tv'){
                    if(type == "add_tv"){
                        this.typeChangeModal = false
                        this.createFields("add_tv")
                        
                        this.fieldsService = JSON.parse(JSON.stringify(this.fieldsTv));
                        this.form_data.service = 1
                        this.createValuesRates()
                        
                    }else{
                        this.typeChangeModal = true
                        this.createFields("change_tv") // Створення списку постачальників послуг
                        this.fieldsService = JSON.parse(JSON.stringify(this.fieldsTvChange));
                        this.form_data = this.service_tv && JSON.parse(JSON.stringify(this.service_tv))
                        this.abonentData.url = `/api/cabletv/get_user/${this.service_tv.id}/edit_service/`
                    }
                    this.form_data.date_tarif_change = new Date().toDateInputValue()
                }else { // if service == 'inet'
                    if(type == "add_inet"){
                        this.loginToChange = JSON.parse(JSON.stringify(this.abonent.username)) 
                        this.typeChangeModal = false
                        this.createFields("add_inet") // Створення списку постачальників послуг
                        this.fieldsService = JSON.parse(JSON.stringify(this.fieldsInet));
                        this.form_data.service = 2
                        
                        
                    }else{
                        this.typeChangeModal = true
                        this.createFields("change_inet") // Створення списку постачальників послуг
                        this.fieldsService = JSON.parse(JSON.stringify(this.fieldsInetChange));
                        this.form_data = this.service_inet_billing && JSON.parse(JSON.stringify(this.service_inet_billing))
                        //console.log("form_data", this.form_data)
                        //this.abonentData.url = `/api/cabletv/get_user/${this.service_inet.id}/edit_service/`
                        
                    }
                    
                    
                }
                console.log("this.form_data: ", this.form_data)
                
                this.countChangeAbonentData = 0
                this.dialog = true;
                
            },
            validate () {
                if (this.$refs.form.validate()) {
                    this.snackbar = true;
                }
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog(){
                this.reset() 
                this.form_data = {}
                this.dialog = false
                //
                
            },
            clearModal(){
                this.abonentData = []
            },
            createValuesRates(){
                console.log("createValuesRates")
                const distributor = this.operators.find(x => x.id == this.form_data.operator)
                this.is_special_tarif_for_distributor = distributor?.is_special_tarifs
                
                if(distributor?.is_special_tarifs) this.fieldsService.find(x => x.name == "tarif").values = this.rates_tv_special
                else this.fieldsService.find(x => x.name == "tarif").values = this.rates_tv    
            },
            apllyFiltersTarifs(){
                console.log(this.$refs.filtersTarifs[0].rates)
                this.fieldsService.find(x => x.name == "tarif").values = this.$refs.filtersTarifs[0].rates
            },
            createFields(service){
                //console.log(service)
                if(service == 'add_tv'){
                    let distributor = this.operators_groups.find(x => x.id == this.abonent.operator_group.id).distributor
                    distributor = distributor.filter(x => x.tv)
                    this.fieldsTv.find(x => x.name == "operator").values = distributor
                    this.form_data.operator = distributor[0].id
                    

                }else if(service == 'change_tv'){
                    let distributor = this.operators_groups.find(x => x.id == this.abonent.operator_group.id).distributor
                    distributor = distributor.filter(x => x.tv)
                    this.fieldsTvChange.find(x => x.name == "operator").values = distributor
                }else if(service == 'change_inet'){
                    /*
                    let distributor = this.operators_groups.find(x => x.id == this.abonent.operator_group.id).distributor
                    distributor = distributor.filter(x => x.inet)
                    this.fieldsInetChange.find(x => x.name == "operator").values = distributor
                    */
                }else if(service == 'add_inet'){
                    let distributor = this.operators_groups.find(x => x.id == this.abonent.operator_group.id).distributor
                    distributor = distributor.filter(x => x.inet)
                    this.fieldsInet.find(x => x.name == "operator").values = distributor
                }
            },
            async sendCreateServiceTv() {
                
                if(this.$refs.form.validate() && this.dialog == true ){
                    this.loadingSubmit = true;
                    this.form_data.user = this.abonent.id
                    
                    await axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "POST",
                        url: this.abonentData.url,
                        data: this.form_data,
                    })
                    .then(() => {
                        //console.log(response);
                        this.$emit('update-abonent');
                        this.changeTarifTv() // Якщо послугу додано виконуємо запит на зміну тарифу
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loadingSubmit = false))
                } 
            },
            async sendChangeServiceTv() {

                if(this.$refs.form.validate()){
                    this.loadingSubmit = true;
                    //this.form_data.tarif = this.form_data.tarif.id
                    await axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "PUT",
                        url: this.abonentData.url,
                        data: this.form_data,
                    })
                    .then(() => {
                        //console.log(response);
                        this.$emit('update-abonent');
                        this.$router.app.$snack.show(this.abonentData.message);
                        this.closeDialog()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loadingSubmit = false))
                } 
            },
            async changeTarifTv() {
                this.loadingSubmit = true;
                await axios({
                    headers: { "Content-type": "application/json; charset=UTF-8" },
                    method: "POST",
                    url: `/api/service/change_tarif/${this.abonent.id}/`,
                    data: this.form_data,
                })
                .then(response => {
                    //console.log(response);
                    this.closeDialog()
                    this.$router.app.$snack.show(response.data);
                    this.$emit('update-abonent');
                })
                .catch(err => {
                    //reject(err)
                    //console.log(err);
                    this.$router.app.$sheet.show("Помилка!", err);
                    this.closeDialog()
                    //console.log(this.$route.query.next)
                })
                .finally(() => (this.loadingSubmit = false))
            },
            async sendCreateServiceInet(){
                
                if(this.$refs.form.validate()){
                    this.loadingSubmit = true;
                    this.form_data.user = this.abonent.id
                    if(this.loginToChange != this.abonent.username) {
                        let result = await this.changeLogin()
                        console.log(result)
                        if(!result) return
                    }
                    
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "POST",
                        url: this.abonentData.url,
                        data: this.form_data,
                    })
                    .then(() => {
                        //console.log(response);
                        this.$router.app.$snack.show(this.abonentData.message);
                        this.closeDialog()
                        this.$emit('update-abonent');
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err)
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loadingSubmit = false))
                } 
            },
            async sendChangeServiceInet(){
                if(this.$refs.form.validate()){
                    this.loadingSubmit = true;
                    //this.form_data.tarif = this.form_data.tarif.id
                    axios({
                        method: "POST",
                        url: this.$router.app.devUrlBillingAPI,
                        data: {req_cmd: "changeUserServiceInet", pcode: this.abonent?.user_pcode, ...this.form_data }
                    })
                    .then(response => {
                        //console.log(response.data.resp_status)
                        if(response.data.resp_status == "OK"){
                            this.$emit('update-abonent');
                            this.$router.app.$snack.show(this.abonentData.message);
                            this.closeDialog()
                        }else if(response.data.resp_status == "REJECT"){
                            this.$router.app.$sheet.show("Помилка з білінгу!", response.data.resp_errmsg);
                        }
                        //console.log(response);
                       
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loadingSubmit = false))
                } 
            },
            async changeLogin(){
                try {
                const { data } = await axios({
                    headers: { "Content-type": "application/json; charset=UTF-8" },
                    method: "PUT",
                    url: `/api/cabletv/get_user/${this.abonent.id}/`,
                    data: {username: this.loginToChange},
                    })
                    if(data) {
                        this.$emit('update-abonent')
                        return true
                    }else{
                        this.$router.app.$snack.show("Помилка зміни логіну", "deep-orange");
                        return false
                    }
                } catch(err) {
                    //console.log(err);
                    this.$router.app.$sheet.show("Помилка!", err);
                    return false
                }
            
            },
        }
    }
</script>
